import React from "react";
import {Container} from "semantic-ui-react";
import "./NotFound.scss"

export default function NotFound() {
  return (
    <Container className="notfound" textAlign={"center"}>
      <div>
        <img src="https://cdn.0g1s.com/20200826/FjSmJqJh4aDJnA2-_AjI4iZfm5w_.svg" alt="oops"/>
      </div>
    </Container>
  );
}
