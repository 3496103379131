import React from "react";
import { Container, Grid, Icon } from "semantic-ui-react";
import './Footer.scss'

export default class Footer extends React.Component {
  render() {
    return (
      <footer>
        <Container className='main'>
          <Grid columns={5}>
            <Grid.Column width={16} className={"show"}>
              <div className="title">裂变拉新，找九一裂变</div>
              <div className="subtitle">为客户提供全方位一站式服务</div>
            </Grid.Column>
            <Grid.Column width={5} className={"hide"}>
              <div className="title">裂变拉新，找九一裂变</div>
              <div className="subtitle">我们致力于打造完善的行业解决方案，为您提供全方位一站式服务，助力客户成功，加快业务增长。</div>
            </Grid.Column>
            <Grid.Column width={2} className={"hide"}>
              <h3>相关资源</h3>
              <div className="item">
                <a href="https://mp.weixin.qq.com/" target="_blank" rel="noopener noreferrer">微信公众平台</a>
              </div>
              <div className="item">
                <a href="https://pay.weixin.qq.com/" target="_blank" rel="noopener noreferrer">微信支付</a>
              </div>
              <div className="item">
                <a href="https://xiaomark.com/" target="_blank" rel="noopener noreferrer">小码短链接</a>
              </div>
            </Grid.Column>
            <Grid.Column width={2} className={"hide"}>
              <h3>联系我们</h3>
              <div className="item">
                <a href="https://blog.imxfly.com/" target="_blank" rel="noopener noreferrer">博客</a>
              </div>
              <div className="item">
                <a href="https://twitter.com/imxfly" target="_blank" rel="noopener noreferrer">Twitter</a>
              </div>
              <div className="item">
                <a href="https://weibo.com/imxfly" target="_blank" rel="noopener noreferrer">微博</a>
              </div>
            </Grid.Column>
            <Grid.Column width={2} className={"hide"}>
              <h3>帮助</h3>
              <div className="item">
                <a href="/">常见问题</a>
              </div>
              <div className="item">
                <a href="/">报告Bug</a>
              </div>
            </Grid.Column>
            <Grid.Column width={4} className={"hide"}>
              <h3>友情链接</h3>
              <div className="item">
                <a href="https://0g1s.com">灵光一闪（杭州）科技有限公司</a>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
        <div className='copyright'>
          <Container>
            <Grid columns={2}>
              <Grid.Column width={9} textAlign={"left"}>
              © 2019-至今 aiioii.com 版权所有
              </Grid.Column>
              <Grid.Column width={7} textAlign={"right"}>
                <span style={{ marginRight: '3px' }}>Made</span>
                <Icon name={"heart"} color={"red"} />
                <span>with 91 Team</span>
              </Grid.Column>
            </Grid>
          </Container>
          <Container>
            <Grid columns={1}>
              <Grid.Column width={16}>
                <img style={{ marginRight: '10px', marginBottom: '3px' }} src="/beian.png" alt="beian" />
                <a className="external-source-link" href="/">蜀ICP备20017669号-3</a>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
      </footer>
    )
  }
}
