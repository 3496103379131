import React from "react";
import {Link, Route, Switch} from "react-router-dom";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Home from "./pages/home/Home";
import ChangeLog from "./pages/changelog/ChangeLog";
import NotFound from "./pages/common/NotFound";
import {Menu, Sidebar} from "semantic-ui-react";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  hideSidebar = () => this.setState({visible: false})

  render() {
    return (
      <div>
        <Sidebar.Pushable>

          <Sidebar
            as={Menu}
            animation={"push"}
            direction={"right"}
            width={"thin"}
            vertical
            visible={this.state.visible}
          >
            <Link to={"/"} onClick={this.hideSidebar}>
              <Menu.Item
                name="home"
              >
                首页
              </Menu.Item>
            </Link>

          </Sidebar>

          <Sidebar.Pusher>
            <Header handleVisible={() => {
              this.setState({visible: !this.state.visible})
            }} />
            <Switch>
              <Route exact path={"/"}>
                <Home/>
              </Route>
              <Route exact path={"/changelog"}>
                <ChangeLog/>
              </Route>
              <Route path={"*"}>
                <NotFound/>
              </Route>
            </Switch>
            <Footer/>
          </Sidebar.Pusher>

        </Sidebar.Pushable>
      </div>
    );
  }
}
