import React from "react";
import { Container, Feed } from 'semantic-ui-react'
import "./ChangeLog.scss"

export default class ChangeLog extends React.Component {
  render() {
    return (
      <div className="changelog">
        <Container>
          <Feed>
            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-elliot.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.1.2</Feed.User>
                  <Feed.Date>2020-11-27</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span>新增打卡皮肤选择功能。后续会增加多个皮肤模板，老板们定做皮肤记得加钱。在后台打卡设置里设置。</span>
                </Feed.Extra>

                <Feed.Extra images>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201127_1.png">
                    <img src='https://console.aiioii.com/changelog_20201127_1.png' alt="img" />
                  </a>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-helen.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.1.1</Feed.User>
                  <Feed.Date>2020-11-25</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span>【更新】系统支持修改打卡天数啦～在打卡设置里修改。</span>
                </Feed.Extra>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span>【更新】口令红包新增未使用口令的新关注用户5分钟后给他推送一条自定义消息。</span>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-joe.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.1.0</Feed.User>
                  <Feed.Date>2020-11-24</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span>新增高危用户检测，高危用户名单是根据网上30w淘客+撸狗名单，昵称匹配，不一定准确，但具有一定参考性，接口地址如下：http://xh.mifanganhuo.cn/，若发现高危用户在进行高危操作，即可拉黑，拉黑后所有系统活动页面均会对他显示维护中。</span>
                </Feed.Extra>

                <Feed.Extra images>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201124_1.jpeg">
                    <img src='https://console.aiioii.com/changelog_20201124_1.jpeg' alt="img" />
                  </a>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-jenny.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.0.9</Feed.User>
                  <Feed.Date>2020-11-23</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span>后台可以分公众号查看单个公众号数据啦~在首页概况左上角选择公众号即可！</span>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-justen.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.0.8</Feed.User>
                  <Feed.Date>2020-11-22</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span>新增打卡用户添加客服兑换功能，在后台打卡设置中开启手动设置并上传二维码。查看手动订单：【公众号管理】-【打卡充值】内可查看所有订单。</span>
                </Feed.Extra>

                <Feed.Extra images>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201122_1.png">
                    <img src='https://console.aiioii.com/changelog_20201122_1.png' alt="img" />
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201122_2.png">
                    <img src='https://console.aiioii.com/changelog_20201122_2.png' alt="img" />
                  </a>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-elliot.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.0.7</Feed.User>
                  <Feed.Date>2020-11-19</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span>增加活动链接检测功能，如果链接被微信屏蔽，会在「九一裂变」公众号内给您发送通知，请务必关注公众号并绑定。</span>
                </Feed.Extra>

                <Feed.Extra images>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201119_1.jpeg">
                    <img src='https://console.aiioii.com/changelog_20201119_1.jpeg' alt="img" />
                  </a>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-helen.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.0.6</Feed.User>
                  <Feed.Date>2020-11-17</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span>打卡提醒新增模式选择：模板消息、卡片推送二选一。</span>
                </Feed.Extra>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span>打卡活动新增总控开关。关闭开关后活动页面会显示维护中，且打卡不提醒。</span>
                </Feed.Extra>

                <Feed.Extra images>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201118_1.png">
                    <img src='https://console.aiioii.com/changelog_20201118_1.png' alt="img" />
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201118_2.png">
                    <img src='https://console.aiioii.com/changelog_20201118_2.png' alt="img" />
                  </a>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-jenny.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.0.5</Feed.User>
                  <Feed.Date>2020-11-16</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span>新增外卖小程序v1.0，查看演示请关注公众号：九一裂变。小程序虽简单，为了以后配合公众号程序一起使用，初始1.0版本敬请期待更新</span>
                </Feed.Extra>

                <Feed.Extra images>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201117_1.jpg">
                    <img src='https://console.aiioii.com/changelog_20201117_1.jpg' alt="img" />
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201117_2.webp">
                    <img src='https://console.aiioii.com/changelog_20201117_2.webp' alt="img" />
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201117_3.webp">
                    <img src='https://console.aiioii.com/changelog_20201117_3.webp' alt="img" />
                  </a>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-joe.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.0.4</Feed.User>
                  <Feed.Date>2020-11-07</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span>美团打卡页面更新界面，无需后台操作，已自动更新。</span>
                </Feed.Extra>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span role="img" aria-label="feature">🌟 </span>
                  <span>新增打卡后自动回复功能，用户打卡完成后，公众号自动发送一条可编辑文字消息。新增打卡后自动回复功能，用户打卡完成后，公众号自动发送一条可编辑文字消息。需要在公众号后台添加【客服消息】功能如下图：</span>
                </Feed.Extra>

                <Feed.Extra images>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201107_1.webp">
                    <img src='https://console.aiioii.com/changelog_20201107_1.webp' alt="img" />
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201107_2.webp">
                    <img src='https://console.aiioii.com/changelog_20201107_2.webp' alt="img" />
                  </a>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-justen.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.0.3</Feed.User>
                  <Feed.Date>2020-11-05</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">🌈 </span>
                  <span role="img" aria-label="feature">🌈 </span>
                  <span role="img" aria-label="feature">🌈 </span>
                  <span>平台全面更新，更多功能，更好用！</span>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-elliot.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.0.2</Feed.User>
                  <Feed.Date>2020-10-29</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span>新增网页后缀修改功能。如果网页被屏蔽，请在公众号基本配置相应活动中修改后缀，然后点击【查看活动链接】部署新的链接到公众号内，位置如下图：</span>
                </Feed.Extra>

                <Feed.Extra images>
                  <a rel="noopener noreferrer" target="_blank" href="https://console.aiioii.com/changelog_20201029_1.png">
                    <img src='https://console.aiioii.com/changelog_20201029_1.png' alt="img" />
                  </a>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image={require('../../assets/avatar-helen.jpg')} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Version 1.0.1</Feed.User>
                  <Feed.Date>2020-10-28</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span role="img" aria-label="feature">❤️ </span>
                  <span>饿了么和美团打卡页面去掉分享朋友圈按钮。经测试用处不大，拉不到人风险还大。</span>
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>
          </Feed>
        </Container>
      </div>
    )
  }
}
